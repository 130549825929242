<template>
  <div
    class="vue-speech-recognition"
    :class="{
      error: error,
      active: isRecording
    }"
    @click="start"
  >
    <span></span>
    <!-- <hr/>
    <hr/>
    <button
        @click="toggleRecording"
        class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {{ isRecording ? 'Stop Recording' : 'Record' }}
      </button> -->
  </div>
</template>

<script>

import events from '../services/events'

export default {
  name: 'VueMicRecognition',
  props: {
    lang: {
      type: String
    },
    deviceId: {
      type: String
    }
  },
  data: () => ({
    mediaRecorder: {
    },
    mediaStream: {},
    recorder: {},
    totalAudioSize: 0,
    error: null,
    isRecording: false,
    audioChunks: [],
    scriptsLoaded: {
      assemblyai: false,
      recordRTC: false
    },

    token: null // AssemblyAI token,

  }),
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  created() {
    this.audioChunks.push([])
  },

  beforeRouteLeave(to, from, next) {
    if (this.confirmStayInRecording()) {
      next(false)
    } else {
      next()
    }
  },
  async mounted() {
    // Automatically load the script and initialize AssemblyAI
    try {
      await this.initializeAssemblyAI()
      await this.initializeRecorder()
      console.log('Dependencies loaded and initialized successfully.')
    } catch (error) {
      console.error('Error during initialization:', error)
    }
  },
  methods: {
    confirmLeave() {
      return window.confirm(
        'Do you really want to leave? you have unsaved Recording in progress!'
      )
    },
    start() {
      this.toggleRecording()
      // if (this.isRecording) {
      //   this.stopRecording()
      //   return
      // }
      // this.startRecording()
    },
    loadScript(url, key) {
      return new Promise((resolve, reject) => {
        if (this.scriptsLoaded[key]) {
          resolve() // Script already loaded
          return
        }
        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.onload = () => {
          this.scriptsLoaded[key] = true
          console.log(`${key} script loaded successfully.`)
          resolve()
        }
        script.onerror = () =>
          reject(new Error(`Failed to load ${key} script.`))
        document.head.appendChild(script)
      })
    },
    async loadDependencies() {
      try {
        // Load AssemblyAI and RecordRTC scripts dynamically
        await this.loadScript(
          'https://www.unpkg.com/assemblyai@latest/dist/assemblyai.umd.min.js',
          'assemblyai'
        )
        await this.loadScript(
          'https://www.WebRTC-Experiment.com/RecordRTC.js',
          'recordRTC'
        )
      } catch (error) {
        console.error('Error loading dependencies:', error)
        throw error // Ensure initialization halts if a dependency fails
      }
    },
    async fetchToken() {
      try {
        //       const port = process.env.VUE_APP_API_PORT || 3000
        //       const baseURL =
        // process.env.NODE_ENV === 'production' ? `` : `http://localhost:${port}`
        //       const response = await fetch(baseURL + '/api/auth/token')
        const data = await events.getAsssemblyAIToken()
        console.log(data)
        // const data = await response.json()
        this.token = data.data.token
        console.log('Token fetched successfully:', this.token)
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    },
    async initializeAssemblyAI() {
      try {
        await this.loadDependencies() // Ensure dependencies are loaded
        if (!this.token) {
          await this.fetchToken() // Fetch token if not already fetched
        }
        // eslint-disable-next-line no-undef
        this.rt = new window.assemblyai.RealtimeTranscriber({ token: this.token })
        console.log('AssemblyAI initialized:', this.rt)
      } catch (error) {
        console.error('Error initializing AssemblyAI:', error)
      }
    },
    async initializeRecorder() {
      try {
        // Check for MediaDevice support
        if (
          !navigator.mediaDevices ||
                !navigator.mediaDevices.getUserMedia
        ) {
          throw new Error(
            'MediaDevices API is not supported in this browser.'
          )
        }

        // const stream = await navigator.mediaDevices.getUserMedia({
        //   audio: true
        // })

        // this.recorder = new window.RecordRTC(stream, {
        //   type: 'audio',
        //   mimeType: 'audio/webm;codecs=pcm',
        //   // eslint-disable-next-line no-undef
        //   recorderType: window.StereoAudioRecorder,
        //   timeSlice: 250,
        //   desiredSampRate: 16000,
        //   numberOfAudioChannels: 1,
        //   bufferSize: 16384,
        //   audioBitsPerSecond: 128000
        // })
        console.log('Recorder initialized:', this.recorder)
      } catch (error) {
        console.error('Error initializing recorder:', error)
      }
    },
    async toggleRecording() {
      if (this.isRecording) {
        await this.stopRecording()
      } else {
        await this.startRecording()
      }
      this.isRecording = !this.isRecording
    },
    async startRecording() {
      console.log('Starting recording..')
      this.transcript = ''
      this.translation = ''
      const data = await events.getAsssemblyAIToken()
      console.log(data)
      this.token = data.data.token
      // const data = await response.json()

      this.rt = new window.assemblyai.RealtimeTranscriber({ token: this.token })

      const texts = {}
      // let translatedText = ''

      this.rt.on('transcript', async (message) => {
        let msg = ''
        texts[message.audio_start] = message.text
        const keys = Object.keys(texts).sort((a, b) => a - b)
        keys.forEach((key) => (msg += ` ${texts[key]}`))
        this.transcript = msg
        console.log(message)
        if (message.message_type === 'FinalTranscript') {
          // const response = await fetch('/translate', {
          //   method: 'POST',
          //   headers: { 'Content-Type': 'application/json' },
          //   body: JSON.stringify({
          //     text: message.text,
          //     target_lang: this.selectedLanguage
          //   })
          // })
          // const data = await response.json()
          // translatedText += ` ${data.translation.text}`
          // this.translation = translatedText

          this.$emit('transcriptionText', JSON.stringify(texts))
        }
      })

      this.rt.on('error', async (error) => {
        console.error(error)
        await this.rt.close()
      })

      this.rt.on('close', () => {
        this.rt = null
      })

      await this.rt.connect()

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaStream = stream
          this.recorder = new window.RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm;codecs=pcm',
            recorderType: window.StereoAudioRecorder,
            timeSlice: 250,
            desiredSampRate: 16000,
            numberOfAudioChannels: 1,
            bufferSize: 16384,
            audioBitsPerSecond: 128000,
            ondataavailable: async (blob) => {
              if (this.rt) {
                this.rt.sendAudio(await blob.arrayBuffer())
              }
            }
          })
          this.recorder.startRecording()
          this.$emit('started')
        })
        .catch((err) => console.error(err))
    },
    async stopRecording() {
      if (this.rt) {
        await this.rt.close(false)
        this.rt = null
      }
      this.mediaStream.getTracks() // get all tracks from the MediaStream
        .forEach(track => track.stop())

      if (this.recorder) {
        this.recorder.stopRecording()
        this.recorder.destroy()
        this.recorder = null
      }
      this.$emit('stopped')
    },
    startRecording1() {
      this.isRecording = true
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: { deviceId: { exact: this.deviceId || 'default' } } })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              this.audioChunks[this.audioChunks.length - 1].push(e.data)
              this.totalAudioSize += e.data.size
            }
          }
          this.mediaRecorder.onstop = () => {
            stream.getTracks() // get all tracks from the MediaStream
              .forEach(track => track.stop())
            this.$emit('stopped')
            this.$emit('transcription', this.audioChunks)
          }
          this.mediaRecorder.start()
          this.isRecording = true
          this.$emit('started')
        })
        .catch((_error) => {

        })
    },
    stopRecording1() {
      if (this.mediaRecorder && this.isRecording) {
        this.isRecording = false
        this.mediaRecorder.stop()
      }
    },
    confirmStayInRecording() {
      return this.isRecording && !this.confirmLeave()
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInRecording()) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    clearAll() {},
    formatTime(milliseconds) {
      const minutes = Math.floor(milliseconds / 60000)
      const seconds = ((milliseconds % 60000) / 1000).toFixed(3)
      return `${minutes}:${seconds}`
    },
    created() {
      window.addEventListener('beforeunload', this.beforeWindowUnload)
    }
  }
}
</script>

<style lang="scss">
.vue-speech-recognition {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

.vue-speech-recognition-mini {
  cursor: pointer;
  position: relative;
  background-color: #4db6ac;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: block;
  transition: all ease-in 250ms;

  &:hover {
    background-color: #26a69a;
  }

  &.error {
    background-color: #bdbdbd;

    &:hover {
      background-color: #9e9e9e;
    }
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
  }

  &:after {
    top: 30%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
  }

  &:before {
    top: 40%;
    left: 43%;
    height: 15%;
    width: 14%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 36%;
    height: 24%;
    width: 28%;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: #fff;
    }

    &:before {
      bottom: 50%;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.125em solid #fff;
      background: none;
      right: 0px;
    }

    &:after {
      top: 50%;
      left: 40%;
      width: 20%;
      height: 25%;
    }
  }
}

@keyframes pulse {
  from {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  }

  to {
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #e53935;
    transform: scale(0.9);
  }
}
</style>
